<template>
    <div class="hello">
        <div class="hello-search">
            <van-search v-model="value" placeholder="请输入搜索关键词" />
            <van-cell title="选择作业日期" :value="date" @click="show = true" />
        </div>
        <hr />
        <van-calendar v-model="show" :min-date="minDate" @confirm="onConfirm" />
        <van-card @click="handleClick" currency num="90" price=" " :desc="date" title="语文" thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"></van-card>
        <van-card @click="handleClick" currency num="90" price=" " :desc="date" title="数学" thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"></van-card>
        <van-card @click="handleClick" currency num="90" price=" " :desc="date" title="英语" thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"></van-card>
    </div>
</template>
<script>
export default {
    name: 'HelloWorld',
    data() {
        return {
            files: {
                name: '',
                type: ''
            },
            minDate: new Date(2010, 1, 1),
            date: '20211022',
            show: false,
            isShowNav: true,
            item1: { color: 'red', 'font-weight': 900 },
            item2: { 'font-style': 'italic' }
        }
    },
    methods: {
        handleClick() {
            console.log('click.in.......')
            this.$router.push({ path: '/hwk/detail', query: { d: `${this.date}` } })
        },
        _leftMove: function() {
            console.log('in...........')
        },
        onClickLeft() {
            Toast('返回')
        },
        onClickRight() {
            Toast('按钮')
        },
        formatDate(date) {
            return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
        },
        onConfirm(date) {
            this.show = false
            console.log(date)
            this.date = this.formatDate(date)
        }
    },
    created() {
        window.document.title = '作业列表'
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello-search {
    /* background-color: gray; */
    border: 1px solid;
}
.p-header {
    font-size: 20px;
}
.title {
    background-color: burlywood;
}
.imgPreview {
    width: 100px;
    height: 100px;
}
.item2 {
    width: 100%;
    position: relative;
    z-index: 5;
    -webkit-transition: 0.1s linear;
    transition: 0.1s linear;
    /* border: solid 1px; */
    margin-top: 12px;
}
.item2 div {
    transition: all 0.1s ease-in 0.5s;
    margin-top: 0px;
}

.item2 div:hover {
    margin-top: 0px;
}
.imgsrc {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
}
.item div {
    transition: all 0.1s ease-in 0.5s;
    width: 100px;
    height: 100px;
    background: yellow;
}
.item div:hover {
    background: red;
    width: 200px;
    height: 200px;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 0 6px #999;
    box-shadow: 0 0 6px #999;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
h1,
h2 {
    font-weight: normal;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: black;
}
.upload-img-5 {
    margin: 5px 0 90px 0;
}
.upload-img-1 {
    margin: 5px 0 15px 0;
}
</style>
